import { createApp } from 'vue';
import LoginIllustration from "../components/LoginIllustration.vue";
import SupportLogo from "../logo/SupportLogo.vue";

const app = createApp({
    components: {
        LoginIllustration,
        SupportLogo
    }
});

app.mount('#publicAuth');
